
import { Action, Getter } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit, FormSelect } from "@/components/forms";
import type { Office } from "@/store/modules/office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSelect,
        FormSubmit,
    },
})
export default class PageAdminUsersCreate extends Vue {
    @Ref() form!: FormClass;

    @Getter("office/all") offices!: Office[];

    @Action("account/invite") sendInvite!: AccountInvite;

    payload: AccountInvitePayload = {
        email: "",
        first_name: "",
        last_name: "",
        locale: "nl_BE",
        role_id: 1,
        office_id: 1,
    };

    roles = [
        { value: 2, label: "Administrator" },
        { value: 1, label: "Medewerker" },
    ];

    get officeOptions() {
        return this.offices.map((o) => {
            return {
                label: o.name,
                value: o.id,
            };
        });
    }

    submit(form: FormClass) {
        this.sendInvite(this.payload)
            .then(() => {
                form.reset();
                this.$toast.open({ message: this.$t("views.settings.admin-users.create_success") as string, type: "success", position: "bottom-right" });
                this.$emit("close");
            })
            .catch((errorResponse: ErrorResponse) => {
                this.$errorResponse = errorResponse;
                form.reset();
            });
    }
}
